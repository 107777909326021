import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Breadcrumb from '../components/breadcrumbs'

class BlogPage extends React.Component {
    render() {
        const posts = this.props.data.allContentfulBlog.edges
        return (
            <div>
                <Layout>
                    <Seo
                        title="Blog - 1st Call Junk Haul Sacramento"
                        description="1st Call Junk Haul Sacramento provides you with the latest tools, news, and advice on Junk Removal and Junk Hauling in Sacramento, California"
                        keywords={["1st Call Junk Haul Sacramento Blog"]}
                        location="/blog"
                        isBlogPage={true}
                        type="BlogPosting"
                    />
                    <div>
                        <div className="border border-gray-200 shadow-lg pt-1 z-70">
                            <nav className="flex container mx-auto px-2 sm:px-6 py-3" aria-label="Breadcrumb">
                                <Breadcrumb links={[
                                    { to: '/', label: 'Home' },
                                    { to: '/blog', label: 'Blog' },
                                ]} />
                            </nav>
                        </div>
                        <div className="pt-5 text-center">
                            <h1 className="text-4xl font-semibold pt-8 mx-auto">
                                1st Call Junk Haul Sacramento Blog
                            </h1>
                            <p className="py-5 text-sm md:text-lg max-w-xl mx-auto px-5">Find the latest news, insights, and resources to help you with your Sacramento Junk Haul needs</p>
                        </div>

                        <div className="container mx-auto py-7 pb-14 px-10">
                            <div className="mx-auto grid gap-x-8 lg:grid-cols-2 xl:grid-cols-3 gap-y-8">
                                {posts && posts.map(({ node: post }) => {
                                    const title = post.title || post.slug
                                    const image = getImage(post.image)
                                    let blogCardTitle = "Blog Post"
                                    return (
                                        <div className="flex flex-col mx-auto border rounded-lg shadow-xl mb-3 bg-white" key={post.slug}>
                                            <article>
                                                <div className="max-w-sm mx-auto hover:opacity-70 rounded-lg">
                                                    <Link to={post.slug} title={blogCardTitle} alt={post.description}>
                                                        <GatsbyImage className="h-56 rounded-t-lg z-0" src="" image={image} title={post.image.title} alt={post.image.description} />
                                                    </Link>
                                                    <Link to={post.slug} title={blogCardTitle} alt={post.description}>
                                                        <div className="px-5 py-5">
                                                            <h2 className="pt-3 font-bold text-xl">{title}</h2>
                                                            <p className="py-3">{post.description}</p>
                                                            <p className="font-semibold">{post.author}</p>
                                                            <p className="pb-2">{post.datePublished}</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </article>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </Layout>
            </div>
        )
    }
}
export default BlogPage
export const pageQuery = graphql`
query {
    site {
        siteMetadata {
            title
        }
    }
    allContentfulBlog(sort: { fields: datePublished, order: DESC }) {
        edges{
            node{
                title
                slug
                author
                body {
                    body
                }
                description
                datePublished(formatString: "MMMM Do, YYYY")
                image {
                   gatsbyImageData(
                       width: 600
                       placeholder: NONE
                       formats: [AUTO, WEBP, AVIF]
                   )
                   title
                   description
                }
            }
        }
    }
}
`